import React, { useState } from 'react';
import WelcomeScreen from './components/screens/Welcome/WelcomeScreen';
import PlanetScreen from './components/screens/Planet/PlanetScreen';
import ChatScreen from './components/screens/Chat/ChatScreen';
import './App.css';

function App() {
  const [results, setResults] = useState([]);
  const [region, setRegion] = useState('');
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  const handleSearch = (searchResults, determinedRegion) => {
    setResults(searchResults || []);
    setRegion(determinedRegion || 'Unknown');
    setSelectedRestaurant(null);
  };

  const handleRestaurantSelect = (restaurant) => {
    setSelectedRestaurant(restaurant);
  };

  return (
    <div className="App">
      {selectedRestaurant ? (
        <ChatScreen restaurant={selectedRestaurant} />
      ) : results.length > 0 ? (
        <PlanetScreen 
          restaurants={results} 
          region={region} // Pass region to determine region
          onRestaurantSelect={handleRestaurantSelect} 
        />
      ) : (
        <WelcomeScreen onSearch={handleSearch} />
      )}
    </div>
  );
}

export default App;
