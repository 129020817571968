import React from 'react';
import './StarshipLoading.css';

function StarshipLoading() {
  return (
    <div className="starship-container">
      <div className="starship"></div>
    </div>
  );
}

export default StarshipLoading;
