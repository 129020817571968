import React, { useState, useEffect, useRef } from 'react';
import './ChatScreen.css';
import axios from 'axios';

// Function to convert military time to 12-hour format
const convertTo12HourFormat = (time) => {
  let hours = parseInt(time.substring(0, 2), 10);
  const minutes = time.substring(2);
  const period = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  return `${hours}:${minutes} ${period}`;
};

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const formatHours = (hours) => {
  return hours.map((hour, index) => (
    <p key={index}>
      {daysOfWeek[hour.day]}: {convertTo12HourFormat(hour.start)} - {convertTo12HourFormat(hour.end)}
    </p>
  ));
};

function ChatScreen({ restaurant }) {
  const [infoChatVisible, setInfoChatVisible] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [googleData, setGoogleData] = useState(null);
  const [error, setError] = useState('');
  const [orders, setOrders] = useState([]); // State for orders
  const [userNotes, setUserNotes] = useState([]); // State for user notes
  const [confirmingOrder, setConfirmingOrder] = useState(false); // State for order confirmation
  const [currentOrder, setCurrentOrder] = useState(''); // State for the current order being confirmed
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchGoogleData = async () => {
      const placeId = restaurant.placeId;
      console.log('Received placeId in ChatScreen:', placeId);

      if (!placeId) {
        setError('Invalid placeId');
        return;
      }

      try {
        const response = await axios.get(`https://us-central1-foodniverse-af391.cloudfunctions.net/api/restaurants/${placeId}`);
        const data = response.data;
        setGoogleData(data);
        const initialGreeting = `Hello! How can I assist you today at ${data.name}?`;
        setMessages([{ text: initialGreeting, sender: 'ai' }]);
      } catch (error) {
        console.error('Error fetching Google data:', error.response ? error.response.data : error.message);
        setError('Failed to fetch restaurant data.');
      }
    };

    fetchGoogleData();
  }, [restaurant]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);


  const toggleInfoChat = () => {
    setInfoChatVisible(!infoChatVisible);
  };

  const handleSendMessage = async () => {
    if (input.trim() === '') return;
  
    const newMessages = [...messages, { text: input, sender: 'user' }];
    setMessages(newMessages);
    setInput('');
  
    if (confirmingOrder) {
      if (input.toLowerCase() === 'add to cart!') {
        addOrder(currentOrder);
        setMessages([...newMessages, { text: `Great! Your order for "${currentOrder}" has been added to the cart. Anything else you would like to order? Say 'order' if you are ready to order more or type 'close order' to finish.`, sender: 'ai' }]);
        setConfirmingOrder(false);
        setCurrentOrder('');
      } else if (input.toLowerCase() === 'maybe later') {
        setMessages([...newMessages, { text: `Okay, let me know if you need anything else.`, sender: 'ai' }]);
        setConfirmingOrder(false);
      } else if (input.toLowerCase() === 'close order') {
        setMessages([...newMessages, { text: `Thank you for your order! Please review the order summary on the right and type 'confirm' to finalize your order.`, sender: 'ai' }]);
        setConfirmingOrder(false);
      } else if (input.toLowerCase().includes('allergy') || input.toLowerCase().includes('dietary') || input.toLowerCase().includes('note')) {
        setUserNotes([...userNotes, input]);
        setMessages([...newMessages, { text: `I've noted that: "${input}". Anything else you would like to order?`, sender: 'ai' }]);
      } else {
        setMessages([...newMessages, { text: `Do you want to add "${input}" to your cart? Please type 'Add to Cart!' to confirm or 'Maybe Later'.`, sender: 'ai' }]);
        setCurrentOrder(input); // Set currentOrder only after user specifies an order item
      }
    } else if (input.toLowerCase() === 'close order') {
      setOrders([]); // Clear orders after confirming the order
      setUserNotes([]); // Clear user notes after confirming the order
      setMessages([...newMessages, { text: `Your order has been confirmed and sent! Thank you for ordering from ${googleData.name}.`, sender: 'ai' }])
    } else {
      try {
        const response = await axios.post('https://us-central1-foodniverse-af391.cloudfunctions.net/api/ai-response', {
          restaurantId: restaurant.placeId,
          query: input,
          chatHistory: newMessages.map(message => [message.sender, message.text])
        });
        let aiResponse = response.data.response;
  
        if (input.toLowerCase() === 'order') {
          aiResponse = "What would you like to order? Please type in your order details.";
          setConfirmingOrder(true);
        } else {
          aiResponse += " Say 'order' if you are ready to order.";
        }
  
        setMessages([...newMessages, { text: aiResponse, sender: 'ai' }]);
      } catch (error) {
        console.error('Error fetching AI response:', error.message);
        setMessages([...newMessages, { text: 'Error fetching AI response.', sender: 'ai' }]);
      }
    }
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const addOrder = (order) => {
    setOrders([...orders, order]);
  };

  const addNote = (note) => {
    setUserNotes([...userNotes, note]);
  };

  const renderServiceInfo = (data) => {
    const services = [
      data.serves_beer && 'Beer',
      data.serves_breakfast && 'Breakfast',
      data.serves_brunch && 'Brunch',
      data.serves_dinner && 'Dinner',
      data.serves_lunch && 'Lunch',
      data.serves_vegetarian_food && 'Vegetarian Food',
      data.serves_wine && 'Wine',
      data.takeout && 'Takeout',
      data.delivery && 'Delivery',
      data.dine_in && 'Dine-in',
      data.wheelchair_accessible_entrance && 'Wheelchair Accessible',
    ].filter(Boolean);

    return services.map((service, index) => (
      <p key={index}>{service}</p>
    ));
  };

  return (
    <div className="chat-screen">
      <div className="sidebar">
      <div className="header-chat">
          Chat with {googleData?.name}'s AI Consultant
          <button onClick={toggleInfoChat} className="toggle-button">
            {infoChatVisible ? '▼' : '▲'}
          </button>
        </div>
        {error && <div className="error-message">{error}</div>}
        {googleData && (
          <div className={`restaurant-info-chat ${infoChatVisible ? '' : 'hidden'}`}>
            <h2>{googleData.name}</h2>
            <p>{googleData.formatted_address}</p>
            <p>{googleData.formatted_phone_number}</p>
            <p>{googleData.price_level}</p>
            <div>
              {googleData.opening_hours?.weekday_text?.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
            <div className="restaurant-info-chat">
              {renderServiceInfo(googleData)}
            </div>
          </div>
        )}
      </div>
      <div className="chat-window">
        <div className="messages" ref={messagesEndRef}>
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.text}
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
      <div className="order-summary">
        <div className="order-summary-card">
          <h2>Order Summary</h2>
          {orders.length === 0 ? (
            <p>No orders yet.</p>
          ) : (
            <ul>
              {orders.map((order, index) => (
                <li key={index}>{order}</li>
              ))}
            </ul>
          )}
        </div>
        <div className="user-notes">
          <h2>Notes</h2>
          {userNotes.length === 0 ? (
            <p>No notes yet.</p>
          ) : (
            <ul>
              {orders.map((userNotes, index) => (
                <li key={index}>{userNotes}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatScreen;