export async function fetchNearbyRestaurants(location, radius, type, keyword) {
  const url = `https://us-central1-foodniverse-af391.cloudfunctions.net/api/places?location=${location}&radius=${radius}&type=${type}&keyword=${keyword}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching nearby restaurants:', error);
    throw error;
  }
}

export async function fetchGooglePlaceDetails(placeId) {
  const url = `https://us-central1-foodniverse-af391.cloudfunctions.net/api/restaurants/${placeId}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching Google Place details:', error);
    throw error;
  }
}