import React from 'react';
import './PlanetScreen.css';
import { fetchGooglePlaceDetails } from '../../../services/googlePlaces';


function PlanetScreen({ restaurants = [], region, onRestaurantSelect }) {
  const handleRestaurantClick = async (placeId) => {
    if (!placeId) {
      console.error('Error: placeId is undefined');
      return;
    }
    console.log('Clicked placeId:', placeId);
    try {
      const googlePlaceDetails = await fetchGooglePlaceDetails(placeId); // Changed function name
      onRestaurantSelect({ placeId, googlePlaceDetails });  // Pass both placeId and googlePlaceDetails
    } catch (error) {
      console.error('Error fetching Google Place details:', error);
    }
  };

  return (
    <div className="planet-screen">
      <div className="stars"></div>
      <div className="header-planet">
        <h1>Planet {region}</h1>
      </div>
      <div className="restaurant-list">
        {restaurants.map((restaurant, index) => (
          <div
            key={index}
            className="restaurant-card"
            onClick={() => handleRestaurantClick(restaurant.place_id)}
          >
            <img src={restaurant.icon} alt={`${restaurant.name} logo`} className="restaurant-logo" />
            <div className="restaurant-info">
              <h2>{restaurant.name}</h2>
              <p>{restaurant.vicinity}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlanetScreen;