import React, { useState, useEffect } from 'react';
import './WelcomeScreen.css';
import StarshipLoading from '../../animations/StarshipLoading';
import { fetchNearbyRestaurants } from '../../../services/googlePlaces';
import logo from '../../../assets/icons/Social_Profile_Mirko_transparent_glow.png';

const prohibitedWords = [
  'ass', 'asshole', 'bastard', 'bitch', 'bollocks', 'bullshit', 'crap', 'cunt', 'damn', 
  'dick', 'dickhead', 'douche', 'fag', 'faggot', 'fuck', 'fucker', 'fucking', 'goddamn', 
  'hell', 'jerk', 'motherfucker', 'nigga', 'nigger', 'piss', 'prick', 'pussy', 'shit', 
  'slut', 'twat', 'whore'
];

const nonMeaningfulSearches = [];

function WelcomeScreen({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [location, setLocation] = useState(null);
  const [startAnimation, setStartAnimation] = useState(false); // New state to control animation

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
          setError('Failed to get your location. Please enable location services and try again.');
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic characters

    if (value !== alphabeticValue) {
      setError('Only alphabetic characters are allowed.');
    } else {
      setError('');
    }

    setSearchTerm(alphabeticValue);
  };

  const containsProhibitedWords = (input) => {
    const lowercasedInput = input.toLowerCase();
    return prohibitedWords.some((word) => lowercasedInput.includes(word));
  };

  const containsNonMeaningfulSearches = (input) => {
    const lowercasedInput = input.toLowerCase();
    return nonMeaningfulSearches.some((search) => lowercasedInput.includes(search));
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      setError('Your Falcon is missing direction!');
      return;
    }

    if (!location) {
      setError('Unable to determine your location.');
      return;
    }

    if (containsProhibitedWords(searchTerm)) {
      setError('Be Polite !!!');
      return;
    }

    if (containsNonMeaningfulSearches(searchTerm)) {
      setError('Please enter a meaningful search term.');
      return;
    }

    setLoading(true);
    setStartAnimation(true); // Start the animation
    setError('');

    try {
      const radius = 5000; // Search radius in meters
      const results = await fetchNearbyRestaurants(`${location.lat},${location.lng}`, radius, 'restaurant', searchTerm);

      if (results.results.length === 0) {
        setError('Sorry, we couldnt find your destination on our celestial map.');
        setLoading(false);
        return;
      }

      const response = await fetch(`https://us-central1-foodniverse-af391.cloudfunctions.net/api/region?term=${searchTerm}`);
      const data = await response.json();
      const region = data.region || 'Kashyyyk';

      onSearch(results.results || [], region);
    } catch (error) {
      setError('Sorry, we couldnt find your destination on our celestial map.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // Function to generate stars
  const generateStars = (numStars) => {
    const stars = [];
    for (let i = 0; i < numStars; i++) {
      const starStyle = {
        top: `${Math.random() * 100}vh`,
        left: `${Math.random() * 100}vw`,
        animationDuration: `${Math.random() * 3 + 2}s`
      };
      stars.push(<div className="star" style={starStyle} key={i}></div>);
    }
    return stars;
  };

  return (
    <div className="welcome-screen">
      <div className="stars">
        {generateStars(1000)}
      </div>
      <div className="brand-logo">
        <a href="https://www.lunatechnologies.ai" target="_blank" rel="noopener noreferrer">
          <img src={logo} alt="brand logo" />
        </a>
      </div>
      <div className="header-welcome">
        <h1>Foodniverse</h1>
      </div>
      <div className="search-container">
        <div className="search-box">
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="What are you craving today?"
            className="search-bar"
          />
          <button onClick={handleSearch} className="search-button">
            ➤
          </button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
      {startAnimation && <StarshipLoading />}
    </div>
  );
}

export default WelcomeScreen;
